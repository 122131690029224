import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    referrals: [],
    pagination: {
      currentPage: 1,
      total: 1,
    },
    currentReferral: null,
    stats: {
      referrals_count: 0,
      avg_referrals_count: 0,
      avg_referrals_price: 0,
    },
    commissionRange: [1, 100],
    priceRange: [1, 1000],
    loadingReferrals: false,
  },
  getters: {
    getReferrals: (state) => state.referrals,
    getReferralById: (state) => (id) => {
      return state.referrals.find((referral) => referral.id === id);
    },
    getPagination: (state) => state.pagination,
    getCurrentReferral: (state) => state.currentReferral,
    getStats: (state) => state.stats,
    getCommissionRange: (state) => state.commissionRange,
    getPriceRange: (state) => state.priceRange,
    isLoadingReferrals: (state) => state.loadingReferrals,
  },
  mutations: {
    setReferrals(state, referrals) {
      state.referrals = referrals.data;
      state.pagination.currentPage = referrals.meta.current_page;
      state.pagination.total = referrals.meta.last_page;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentReferral(state, currentReferral) {
      state.currentReferral = currentReferral;
    },
    setCurrentReferralPatient(state, patient) {
      state.currentReferral.patient = patient;
    },
    setStats(state, stats) {
      state.stats = stats;
    },
    setCommissionRange(state, commissionRange) {
      state.commissionRange = commissionRange;
    },
    setPriceRange(state, priceRange) {
      state.priceRange = priceRange;
    },
    setLoadingReferrals(state, isLoading) {
      state.loadingReferrals = isLoading;
    },
    clearReferrals(state) {
      state.referrals = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
    clearCurrentReferral(state) {
      state.currentReferral = null;
    },
  },
  actions: {
    async fetchReferrals(
      { commit, state },
      { time, date_range, filters, sortBy } = {
        time: null,
        date_range: null,
        filters: null,
        sortBy: null,
      }
    ) {
      const { data } = await http.get(`/referral`, {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferrals", data);
    },
    async checkResults(
      { commit, state },
      { id, time = "current_month", date_range = null, filters, sortBy }
    ) {
      const { data } = await http.get(`referrals/check-results/${id}`, {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferrals", data.referrals);
    },
    async fetchDoctorReferrals(
      { commit, state },
      {
        time = "current_month",
        date_range = null,
        filters,
        sortBy,
        search = null,
      }
    ) {
      const { data } = await http.get("referrals/all-referrals", {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range,
          search,
          ...filters,
          ...sortBy,
          timestamp: Date.now(),
        },
      });
      commit("setReferrals", data.referrals);
      commit("setStats", data.stats);
      commit("doctors/setTopDoctors", data.top_doctors, { root: true });
      commit("referrals/setPriceRange", data.price_range, {
        root: true,
      });
    },
    async fetchAdminReferrals(
      { commit, state },
      { time = "current_month", date_range = null, filters, sortBy }
    ) {
      commit("setLoadingReferrals", true);
      const { data } = await http.get("admin/referrals", {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setReferrals", data.referrals);
      commit("referrals/setPriceRange", data.price_range, {
        root: true,
      });
      commit("setLoadingReferrals", false);
    },
    async fetchCurrentReferral({ commit }, id) {
      return await http
        .get(`/referrals/${id}`, {
          params: {
            timestamp: Date.now(),
          },
        })
        .then(({ data }) => {
          commit("setCurrentReferral", data.referral);
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
    async fetchAdminStats(
      { commit, state },
      { time = "current_month", date_range = null, filters, sortBy }
    ) {
      const { data } = await http.get("admin/referrals/stats", {
        params: {
          page: state.pagination.currentPage,
          time: time,
          date_range: date_range,
          ...filters,
          ...sortBy,
        },
      });
      commit("setStats", data.stats);
      commit("doctors/setTopDoctors", data.top_doctors, { root: true });
    },
    sendAndDownloadReferral({ state }) {
      const windowReference = window.open();

      return http({
        url: `/referrals/send-and-download/${state.currentReferral.id}`,
        method: "POST",
      })
        .then((response) => {
          windowReference.location = response.data.url;
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.forbidden",
              duration: -1,
              ignoreDuplicates: true,
            });
        });
    },
  },
};

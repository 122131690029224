import { createRouter, createWebHistory } from "vue-router";
import http from "@/utils/axios";

import store from "@/store";
const mainPageBreadCrumb = [{ name: "layouts.main_page", link: "Dashboard" }];

const routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "guest" */ "@/layouts/Guest.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
      },
      {
        path: "error",
        name: "Error",
        component: () =>
          import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
      },
      {
        path: "terms-of-use",
        name: "TermsOfUse",
        component: () =>
          import(/* webpackChunkName: "terms" */ "@/views/TermsOfUse.vue"),
      },
      {
        path: "partner-terms-of-use",
        name: "PartnerTermsOfUse",
        component: () =>
          import(
            /* webpackChunkName: "partner-terms" */ "@/views/Doctor/TermsOfUse.vue"
          ),
      },
      {
        path: "privacy-policy",
        name: "PrivacyPolicy",
        component: () =>
          import(
            /* webpackChunkName: "privacy-policy" */ "@/views/PrivacyPolicy.vue"
          ),
      },
      {
        path: "blocked",
        name: "Blocked",
        component: () =>
          import(/* webpackChunkName: "blocked" */ "@/views/Blocked.vue"),
      },
      {
        path: "/:catchAll(.*)",
        component: () =>
          import(/* webpackChunkName: "not-found" */ "@/views/NotFound.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: () =>
      import(/* webpackChunkName: "guest" */ "@/layouts/Guest.vue"),
    children: [
      {
        path: "doctor/register",
        name: "RegisterDoctor",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "register-doctor" */ "@/views/Auth/Doctor/Register.vue"
          ),
      },
      {
        path: "doctor/code",
        name: "AuthCodeDoctor",
        meta: { requiresAuth: true, smsVerified: 0 },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "auth-code-doctor" */ "@/views/Auth/Doctor/Code.vue"
          ),
      },
      {
        path: "doctor/collection-facility",
        name: "AuthFalicityDoctor",
        meta: {
          requiresAuth: true,
          smsVerified: 1,
          hasFacility: false,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "auth-facility-doctor" */ "@/views/Auth/Doctor/Facility.vue"
          ),
      },
      {
        path: "doctor/billing-data",
        name: "AuthBillingDataDoctor",
        meta: {
          requiresAuth: true,
          smsVerified: 1,
          hasFacility: true,
          hasBillingData: false,
        },
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "auth-billing-data-doctor" */ "@/views/Auth/Doctor/BillingData.vue"
          ),
      },
      {
        path: "doctor/login",
        name: "LoginDoctor",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "login-doctor" */ "@/views/Auth/Doctor/Login.vue"
          ),
      },
      // {
      //   path: "patient/register",
      //   name: "RegisterPatient",
      //   meta: { requiresAuth: false },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "register-patient" */ "@/views/Auth/Patient/Register.vue"
      //     ),
      // },
      // {
      //   path: "patient/code",
      //   name: "AuthCodePatient",
      //   meta: { requiresAuth: false },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "auth-code-patient" */ "@/views/Auth/Patient/Code.vue"
      //     ),
      // },
      {
        path: "patient/login",
        name: "LoginPatient",
        meta: { requiresAuth: false },
        beforeEnter: [transaction],
      },
      {
        path: "password/email",
        name: "PasswordEmail",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "password-email" */ "@/views/Auth/Password/Email.vue"
          ),
      },
      {
        path: "password/reset/:code",
        name: "PasswordReset",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "password-reset" */ "@/views/Auth/Password/Reset.vue"
          ),
      },
      {
        path: "doctor/create/:code",
        name: "DoctorAccountCreated",
        meta: { requiresAuth: false },
        component: () =>
          import(
            /* webpackChunkName: "password-reset" */ "@/views/Auth/Doctor/AccountCreated.vue"
          ),
      },
      // {
      //   path: "auth/patient/complete-data",
      //   name: "AuthPatientCompletionOfData",
      //   meta: { requiresAuth: false },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "auth-code-doctor" */ "@/views/Auth/Patient/CompletionOfData.vue"
      //     ),
      // },
    ],
  },
  {
    path: "/dashboard/referral/facility/:id?",
    name: "DoctorReferralFacility",
    meta: { role: ["doctor"], status: "active" },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "doctor-referral-facility" */ "@/views/Auth/Doctor/Facility.vue"
      ),
  },
  {
    path: "/dashboard",
    name: "User",
    meta: {
      requiresAuth: true,
      smsVerified: 1,
      hasFacility: true,
      hasBillingData: true,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "@/layouts/User.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/User/Dashboard.vue"
          ),
      },
      {
        path: "account",
        name: "Account",
        component: () =>
          import(/* webpackChunkName: "account" */ "@/views/User/Account.vue"),
      },
      {
        path: "referral/:id",
        name: "DoctorReferral",
        meta: { role: ["doctor", "admin"], status: "active" },
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "referral" */ "@/views/Doctor/Referral.vue"
          ),
      },
      {
        path: "referral/:id/detail",
        name: "PatientReferralDetail",
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "referral" */ "@/views/Patient/ReferralsDetail.vue"
          ),
      },

      {
        path: "referral/:id/results",
        name: "ReferralResults",
        meta: { status: "active" },
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "referral-results" */ "@/views/User/Results.vue"
          ),
      },
      {
        path: "referrals/:id/",
        name: "ReferralFromReferralGroup",
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "referral" */ "@/views/Patient/ReferralsDetail.vue"
          ),
      },

      //DOCTOR'S PAGES
      {
        path: "settlements",
        name: "DoctorSettlements",
        meta: { role: ["doctor"], status: "active" },
        component: () =>
          import(
            /* webpackChunkName: "doctor-settlements" */ "@/views/Doctor/Settlements.vue"
          ),
      },
      {
        path: "referral-create",
        name: "DoctorReferralCreate",
        meta: {
          role: ["doctor"],
          status: "active",
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "layouts.examintons_list" },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "doctor-referral-create" */ "@/views/Doctor/ReferralCreate.vue"
          ),
      },
      {
        path: "referral-edit/:id",
        name: "DoctorReferralEdit",
        meta: { role: ["doctor"], status: "active" },
        component: () =>
          import(
            /* webpackChunkName: "doctor-referral-edit" */ "@/views/Doctor/ReferralCreate.vue"
          ),
      },
      {
        path: "referral-history",
        name: "DoctorReferralHistory",
        meta: { role: ["doctor"], status: "active" },
        component: () =>
          import(
            /* webpackChunkName: "doctor-referral-history" */ "@/views/Doctor/ReferralHistory.vue"
          ),
      },
      {
        path: "own-package/:id",
        name: "DoctorOwnPackage",
        props: (route) => ({ id: route.params.id * 1 }),
        meta: {
          role: ["doctor"],
          status: "active",
          breadcrumb: [
            ...mainPageBreadCrumb,
            { name: "layouts.examintons_list", link: "DoctorReferralCreate" },
            { name: "layouts.own_packages", link: "DoctorReferralCreate" },
            { name: ":name" },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "doctor-own-package" */ "@/views/Doctor/OwnPackage.vue"
          ),
      },
      {
        path: "patient-list",
        name: "DoctorPatientList",
        meta: { role: ["doctor"], status: "active" },
        component: () =>
          import(
            /* webpackChunkName: "doctor-patient-list" */ "@/views/Doctor/PatientList.vue"
          ),
      },
      {
        path: "patient/:id",
        name: "DoctorPatientCard",
        meta: { role: ["doctor"], status: "active" },
        component: () =>
          import(
            /* webpackChunkName: "doctor-referral" */ "@/views/Doctor/PatientCard.vue"
          ),
      },
      //PATIENT'S PAGES
      // {
      //   path: "referral/:id/facility",
      //   name: "PatientReferralFacility",
      //   meta: { role: ["patient"] },
      //   props: (route) => ({ id: route.params.id * 1 }),
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "patient-referral-facility" */ "@/views/Auth/Doctor/Facility.vue"
      //     ),
      // },
      // {
      //   path: "referral/:id/examinations",
      //   name: "PatientExaminations",
      //   meta: { role: ["patient"] },
      //   props: (route) => ({ id: route.params.id * 1 }),
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "patient-examinations" */ "@/views/Patient/Examinations.vue"
      //     ),
      // },
      // {
      //   path: "referral/:id/summary",
      //   name: "PatientReferral",
      //   meta: { role: ["patient"] },
      //   props: (route) => ({ id: route.params.id * 1 }),
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "patient-referral" */ "@/views/Patient/Referral.vue"
      //     ),
      // },
      // {
      //   path: "paid/:code",
      //   name: "Paid",
      //   meta: { role: ["patient"] },
      //   props: true,
      //   component: () =>
      //     import(/* webpackChunkName: "paid" */ "@/views/Patient/Paid.vue"),
      // },
      //ADMIN'S PAGES
      {
        path: "doctors",
        name: "AdminDoctors",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-doctors" */ "@/views/Admin/Doctors.vue"
          ),
      },
      {
        path: "raports",
        name: "AdminRaports",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-doctors" */ "@/views/Admin/Raports.vue"
          ),
      },
      {
        path: "raports-links",
        name: "AdminRaportsLinks",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-doctors" */ "@/views/Admin/DailyRaportLinks.vue"
          ),
      },
      {
        path: "referrals",
        name: "AdminReferrals",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-orders" */ "@/views/Admin/Referrals.vue"
          ),
      },
      {
        path: "stats",
        name: "AdminStats",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-orders" */ "@/views/Admin/Stats.vue"
          ),
      },
      {
        path: "invoices",
        name: "AdminInvoices",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-invoices" */ "@/views/Admin/Invoices.vue"
          ),
      },
      {
        path: "promotions",
        name: "AdminPromotions",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-invoices" */ "@/views/Admin/Promotions.vue"
          ),
      },
      {
        path: "live-stats",
        name: "LiveStats",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-live-stats" */ "@/views/Admin/LiveStats.vue"
          ),
      },
      {
        path: "createdinvoices",
        name: "AdminCreatedInvoices",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-invoices" */ "@/views/Admin/CreatedInvoices.vue"
          ),
      },
      {
        path: "features",
        name: "Features",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-features" */ "@/views/Admin/Features.vue"
          ),
      },
      {
        path: "artisan",
        name: "Artisan",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-features" */ "@/views/Admin/Artisan.vue"
          ),
      },
      {
        path: "raports",
        name: "Raports",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-features" */ "@/views/Admin/Raports.vue"
          ),
      },
      {
        path: "referral-errors",
        name: "AdminReferralErrors",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-features" */ "@/views/Admin/ReferralError.vue"
          ),
      },
      {
        path: "detail-referral-errors/:id",
        name: "AdminDetailReferralErrors",
        meta: { role: ["admin"] },
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "admin-features" */ "@/views/Admin/DetailReferralError.vue"
          ),
      },
      {
        path: "doctor-create",
        name: "AdminCreateDoctor",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-doctor-create" */ "@/views/Admin/DoctorCreate.vue"
          ),
      },
      {
        path: "doctor-edit/:id",
        name: "AdminUpdateDoctor",
        meta: { role: ["admin"] },
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "patient-referral-examination" */ "@/views/Admin/DoctorCreate.vue"
          ),
      },
      {
        path: "doctor/:id",
        name: "AdminDoctorCard",
        meta: { role: ["admin"] },
        props: (route) => ({ id: route.params.id * 1 }),
        component: () =>
          import(
            /* webpackChunkName: "patient-referral-examination" */ "@/views/Admin/DoctorCard.vue"
          ),
      },
      {
        path: "doctors",
        name: "AdminDoctorsMessage",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "admin-doctors-message" */ "@/views/Admin/MessageForm.vue"
          ),
      },
      {
        path: "patient-list",
        name: "AdminPatientList",
        meta: { role: ["admin"] },
        component: () =>
          import(
            /* webpackChunkName: "doctor-patient-list" */ "@/views/Admin/PatientList.vue"
          ),
      },
    ],
  },
];

function transaction() {
  window.location.href = process.env.VUE_APP_CART_PATIENT_URL;
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  store.commit("auth/clearErrors");
  const token = localStorage.getItem("authtoken");
  if (!token) {
    setTimeout(() => {
      store.commit("loader/userLoaded");
    }, 500);
  }

  if (to.meta.requiresAuth && !token && to.name !== "PatientExaminations") {
    if (to.name !== "ReferralResults") {
      next({ name: "Home" });
    }
  }

  if (token) {
    await store
      .dispatch("user/fetchCurrentUser")
      .then(() => {
        setTimeout(() => {
          store.commit("loader/userLoaded");
        }, 200);
      })
      .catch(({ response }) => {
        if (response && response.status === 401) {
          localStorage.removeItem("authtoken");
          return next({ name: "Home" });
        }
      });
    let role = store.getters["user/getCurrentUserRole"];
    let sms = store.getters["user/getCurrentUserVerified"];
    let facility = store.getters["user/getCurrentUserFacility"];
    let status = store.getters["user/getCurrentUserStatus"];
    let hasBillingData = store.getters["user/getHasBillingData"];

    if (to.meta.requiresAuth === false) {
      return next({ name: "Dashboard" });
    }

    if (status !== "active" && to.name === "DoctorReferralCreate") {
      return next({ name: "Dashboard" });
    }

    if (role === "patient" && to.meta.requiresAuth === true) {
      return (window.location.href = process.env.VUE_APP_CART_PATIENT_URL);
    }
    if (to.meta.requiresAuth && to.meta.smsVerified !== sms) {
      if (role === "doctor") {
        if (to.name === "AuthCodeDoctor") {
          return next({ name: "Dashboard" });
        }
        return next({ name: "AuthCodeDoctor" });
      }
      // if (role === "patient") {
      //   if (to.name === "AuthCodePatient") {
      //     return next({ name: "Dashboard" });
      //   }
      //   return next({ name: "AuthCodePatient" });
      // }
      return next();
    }

    if (
      role === "doctor" &&
      to.meta.requiresAuth &&
      to.meta.smsVerified === sms &&
      to.meta.hasFacility === true &&
      to.meta.hasFacility !== facility
    ) {
      return next({ name: "AuthFalicityDoctor" });
    }

    if (
      role === "doctor" &&
      to.meta.hasBillingData === true &&
      to.meta.hasBillingData !== hasBillingData
    ) {
      return next({ name: "AuthBillingDataDoctor" });
    }

    if (
      role === "doctor" &&
      to.meta.hasBillingData === false &&
      hasBillingData === true
    ) {
      return next({ name: "Dashboard" });
    }

    if (to.meta.role) {
      if (
        to.meta.requiresAuth &&
        to.meta.smsVerified === sms &&
        !to.meta.role.includes(role)
      ) {
        return next({ name: "Dashboard" });
      }
    }
    if (status === "blocked" && role === "doctor") {
      localStorage.removeItem("authtoken");
      return next({ name: "Blocked" });
    }

    if (from.name === "AdminDoctors" && to.name !== "AdminDoctorsMessage")
      store.commit("message/clearAll");

    if (
      from.name === "DoctorReferralEdit" &&
      to.name !== "DoctorReferralCreate"
    ) {
      sessionStorage.removeItem("cart");
      store.commit("message/clearAll");
    }

    return next();
  }

  if (
    (!token && to.name === "PatientExaminations") ||
    (!token && to.name === "ReferralResults")
  ) {
    await http
      .get(`is-register/${to.params.id}`)
      .then(({ data }) => {
        return next({
          name: data.is_register ? "LoginPatient" : "RegisterPatient",
        });
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  next();
});

export default router;
